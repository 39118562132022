@font-face {
  font-family: "muli-light";
  src: url("./../font/Muli-Light.ttf"); /* IE9 Compat Modes */
  src: url("./../font/Muli-Light.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "muli-regular";
  src: url("./../font/Muli-Regular.ttf"); /* IE9 Compat Modes */
  src: url("./../font/Muli-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "muli-medium";
  src: url("./../font/Muli-SemiBold.ttf"); /* IE9 Compat Modes */
  src: url("./../font/Muli-SemiBold.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "muli-semi-bold";
  src: url("./../font/Muli-SemiBold.ttf"); /* IE9 Compat Modes */
  src: url("./../font/Muli-SemiBold.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "muli-bold";
  src: url("./../font/Muli-Bold.ttf"); /* IE9 Compat Modes */
  src: url("./../font/Muli-Bold.ttf") format("truetype"); /* Safari, Android, iOS */
}
