.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 260px;
  display: block;
  z-index: 9;
  color: #fff;
  font-weight: 200;
  background-size: cover;
  overflow-x: hidden;
  background-position: center center;
  .sidebar-wrapper {
    position: relative;
    height: calc(100vh - 75px);
    z-index: 4;
    padding-bottom: 30px;
  }
  .nav {
    // margin-top: 20px;
    li {
      width: 100%;
      .card {
        .card-body {
          a {
            color: #ffffff;
            border-radius: 4px;
            margin: 5px 10px 0;
          }
        }
      }
      > a {
        color: #ffffff;
        border-radius: 4px;
        margin: 5px 10px 0;
      }
    }
    .caret {
      top: 24px;
      position: absolute;
      right: 15px;
    }
    .span-shortname {
      font-size: 12px;
      float: left;
      margin-right: 15px;
      line-height: 30px;
      width: 30px;
      text-align: center;
    }
    i {
      font-size: 18px;
      float: left;
      margin-right: 15px;
      line-height: 30px;
      width: 30px;
      text-align: center;
      opacity: 0.8;
    }
  }
}

.sidebar,
body > .navbar-collapse {
  &:after,
  &:before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }
}

.main-panel {
  background: rgba(203, 203, 210, 0.15);
  position: relative;
  float: right;
  width: calc(100% - 260px);
  height: 100vh;
  @media (max-width: 991.98px) {
    width: 100% !important;
  }
}

.icon-search {
  @media (max-width: 767.98px) {
    width: 190px !important;
  }
}

.main-panel,
.navbar-fixed,
.sidebar,
.sidebar-wrapper,
.logo-wrapper {
  -webkit-transition-property: top, bottom, width;
  -o-transition-property: top, bottom, width;
  transition-property: top, bottom, width;
  -webkit-transition-duration: 0.2s, 0.2s, 0.35s;
  -o-transition-duration: 0.2s, 0.2s, 0.35s;
  transition-duration: 0.2s, 0.2s, 0.35s;
  -webkit-transition-timing-function: linear, linear, ease;
  -o-transition-timing-function: linear, linear, ease;
  transition-timing-function: linear, linear, ease;
  -webkit-overflow-scrolling: touch;
}

.sidebar-overlay,
.themesetting-overlay {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  opacity: 1;
  transition: opacity 1s;
  width: 100%;
  height: 100%;
}

.hor_mobile_sidebar {
  display: none;
  @media (max-width: 991.98px) {
    display: block;
    // .close-drawer-icon, .sidebar-overlay {
    //     display: block !important;
    // }
  }
}

.hor_menu {
  max-width: 100% !important;
  // margin-top: 1.6rem !important;
  @media (max-width: 991.98px) {
    display: none;
  }
  .popover {
    max-width: 100% !important;
  }
  .menu_section {
    min-width: 150px;
    .hor_heading {
      padding: 15px 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      span {
        font-size: 16px;
        @include ele-font("muli-semi-bold");
      }
      @media (min-width: 767px) and (max-width: 991.98px) {
        padding: 8px;
        span {
          font-size: 14px;
        }
      }
    }
  }
  .hor_nav-link {
    padding: 10px;
    display: block;
    color: black;
    border-radius: 6px;
    transition: all 0.3s ease-in;
    span {
      font-size: 13px !important;
      color: #6c757d;
      @include ele-font("muli-medium");
    }
    &:hover {
      text-decoration: none;
      span {
        font-family: "muli-bold";
      }
    }
    &.active {
      text-decoration: none;
      background-color: #f4f5f7;
      span {
        font-family: "muli-bold";
      }
    }
    @media (min-width: 767px) and (max-width: 991.98px) {
      padding: 8px;
      span {
        font-size: 12px;
      }
    }
  }
}

.text-upper {
  text-transform: uppercase;
}
