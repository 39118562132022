/* .custom-react-table-theme-class th{
    min-width: auto;
    background: #f8fafd 0 0 no-repeat padding-box;
    border: none!important;
    color: #2e3a44!important;
    text-transform: uppercase;
    font-size: 14px;
    height: 50px;
    padding: 0;
    text-align: center;
} */
/* .custom-react-table-theme-class th{
    min-width: auto !important;
} */
/* * {
  font-family: "Poppins", sans-serif !important;
}
i {font-family: "Font Awesome 5 Free" !important} */
.custom-react-table-theme-class th {
  min-width: auto !important;
}
.custom-react-table-theme-class thead tr th:last-child,
.custom-react-table-theme-class tbody tr td:last-child {
  text-align: center;
}
.custom-react-table-theme-class,
.custom-react-table-theme-class th,
.custom-react-table-theme-class td {
  border: 0 !important;
  font-size: 14px;
  font-weight: 500;
  /* text-align: center; */
}
.custom-react-table-theme-class tbody td {
  font-weight: 500;
}
.custom-react-table-theme-class thead tr {
  background-color: #f8fafd !important;
}
.table-container {
  border-radius: 0.5rem;
  box-shadow: 0 2px 6px rgb(0 25 46 / 6%);
  background-color: #fff;
  padding: 15px;
  margin: 15px 0 !important;
}

.input-group mb-3 .form-control {
  padding-right: 35px;
}

.input-group svg {
  z-index: 4;
  position: absolute;
  right: 7px;
  top: 5px;
}
span.red {
  color: red;
}
input[type="file"] {
  padding: 3px !important;
}

.loaderdiv-outer {
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
}
.loaderbox {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 20vh;
  height: 20vh;
  background: #fff;
  padding: 30px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addbtncolor {
  background-color: #ffce01 !important;
  color: #000 !important;
  border-color: #ffce01 !important;
}
.addbtncolor:hover {
  background-color: #000 !important;
  color: #fff !important;
}
.modalcancelbutton {
  background-color: transparent !important;
  color: #000 !important;
}
.modalcancelbutton:hover {
  background-color: #000 !important;
  color: #fff !important;
}
.modaladdbutton {
  background-color: #ffce01 !important;
  color: #000 !important;
  border-color: #ffce01 !important;
}
.modaladdbutton:disabled {
  opacity: 0.4 !important;
}
.btn-secondary:focus {
  box-shadow: none !important;
}
.siteenterbutton {
  background-color: #ffce01 !important;
  border-color: #ffce01 !important;
  color: #000 !important;
}
.siteenterbutton:disabled:hover {
  background-color: #ffce01 !important;
  border-color: #ffce01 !important;
  color: #000 !important;
}
.siteenterbutton:hover {
  background-color: #000 !important;
  color: #fff !important;
}
.generate_btn {
  background-color: #ffce01;
  color: #000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: 1px solid #ffce01;
}
.generate_btn:hover {
  background-color: #000;
  color: #fff;
  border-color: #000;
}
.approvebtn {
  background-color: #ffce01 !important;
  color: #000 !important;
  border: 1px solid #ffce01 !important;
  display: block !important;
  margin: 0 auto;
}
.approvebtn:hover {
  background-color: #000 !important;
  color: #fff !important;
  border-color: #000 !important;
}
.rquesttittle {
  font-size: 20px;
  font-weight: 600;
}
.requesttextarea {
  height: 120px;
}
.approveddiv {
  text-align: center;
}
.Table__pagination .Table__visiblePagesWrapper .Table__pageButton--active,
.Table__pagination .Table__visiblePagesWrapper .Table__pageButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  margin: 0px 2.5px;
}
.Table__pagination .Table__pageButton svg {
  color: #000;
  width: 16px;
  height: 16px;
}

.Table__pagination .Table__pageButton:disabled {
  opacity: 0.5 !important;
  cursor: default !important;
}

.Table__pagination .Table__pageButton:hover,
.Table__pagination .Table__visiblePagesWrapper .Table__pageButton--active,
.Table__pagination .Table__visiblePagesWrapper .Table__pageButton:hover {
  background: rgba(0, 0, 0, 0.08);
  border-radius: 50% !important;
}
.Table__pagination .Table__pageButton:disabled:hover {
  background-color: transparent;
}
.disablefiled {
  color: #bbb !important;
}
.show-room-button {
  background: green;
  margin-left: 10px;
  padding: 5px;
  color: white;
}
